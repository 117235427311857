// compileExpanded ../css/$1.css
@import "variables";
@import "vendors/bourbon";

/*
* Name:         #{ $template-name }
* Written by:   Unifato - (http://unifato.com)
* Version:      #{ $template-version }
*/

/* Page Pre Loader
========================*/
body {
  &:after {
    content: "";
    @include position(fixed, 0);
    z-index: 99;
    background: #fff;
  }
  &.pace-done:after {
    display: none;
  }
}

.pace {
  @include position(fixed, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 100;
  transition: all 0.3s ease;

  body.rtl & {
    direction: ltr;
  }
}

.pace-active {
  opacity: 1;
  visibility: visible;
}
.pace-active * {
  box-sizing: border-box;
}

.pace-inactive {
  opacity: 0;
  visibility: hidden;
}

.pace-activity  {
  animation: rotate_something 1s infinite;  
  height: 50px;
  width: 50px;
}

.pace-activity:before,
.pace-activity:after {   
  border-radius: 50%;
  content: '';
  display: block;
  height: 20px;  
  width: 20px;
}
.pace-activity:before {
  animation: ball1 1s infinite;  
  background-color: #cb2025;
  box-shadow: 30px 0 0 #f8b334;
  margin-bottom: 10px;
}
.pace-activity:after {
  animation: ball2 1s infinite; 
  background-color: #00a096;
  box-shadow: 30px 0 0 #97bf0d;
}

@keyframes rotate_something {
  0% { 
    -webkit-transform: rotate(0deg) scale(0.8); 
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% { 
    -webkit-transform: rotate(360deg) scale(1.2); 
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% { 
    -webkit-transform: rotate(720deg) scale(0.8); 
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }
  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }
  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}
